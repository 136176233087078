import React, { Component } from 'react'
import styled from 'styled-components'
import { graphql } from 'gatsby'
import slugify from 'slugify'

slugify.extend({ ä: 'ae' })
slugify.extend({ ö: 'oe' })
slugify.extend({ ü: 'ue' })
slugify.extend({ Ä: 'AE' })
slugify.extend({ Ö: 'OE' })
slugify.extend({ Ü: 'UE' })
slugify.extend({ ',': '.' })

import HeadingMain from '../components/HeadingMain'
import Wine from '../components/Wine'
import SEO from '../components/SEO'

import { formatPrice } from '../utilities/formatPrice'

const Container = styled.div`
  max-width: ${p => p.theme.maxPageWidth};
  margin-left: auto;
  margin-right: auto;
  padding-left: ${p => p.theme.paddingOnSides};
  padding-right: ${p => p.theme.paddingOnSides};
`

const WineHeading = styled.h1`
  font-family: Montserrat, sans-serif;
  font-weight: 300;
  font-size: 36px;
  text-align: center;
  color: ${p => p.theme.textColorDark};
  letter-spacing: 4px;
  padding-bottom: 20px;
  border-bottom: 1px solid ${p => p.theme.lightGray};
  margin-bottom: 60px;
  /* For the scrollIntoView Position */
  margin-top: -134px;
  padding-top: 134px;
  @media (max-width: 460px) {
    font-size: 28px;
    letter-spacing: 3px;
  }
  @media (max-width: 380px) {
    font-size: 24px;
    letter-spacing: 2px;
  }
`

const WineSection = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(290px, 1fr));
  /* display: flex; */
  /* flex-wrap: wrap; */
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 54px;
  @media (max-width: 500px) {
    padding-left: 0;
    padding-right: 0;
  }
  @media (max-width: 420px) {
    grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
  }
  /* FOR NON-GRID-SUPPORT */
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  @supports (display: grid) {
    width: auto;
    margin-left: 0;
    margin-right: 0;
  }
`

class Weine extends Component {
  render() {
    const { wineCollections } = this.props.data
    return (
      <React.Fragment>
        <SEO
          title="Unsere Weine"
          description="Frische, fruchtige Weißweine hin zu kräftigen, facettenreichen Rotweinen. Online zum Ab Hof Preis."
        />
        <HeadingMain
          mainHeading="Willkommen in unserer digitalen Wein-Oase!"
          subHeading="Unsere Weine können Sie auch online bestellen!"
          subSubHeading="Und das zum Ab-Hof-Preis."
        />
        <Container>
          {wineCollections.edges.map(
            ({ node }) =>
              node.products.length != 0 && (
                <React.Fragment key={node.id}>
                  <WineHeading id={slugify(node.title).toUpperCase()}>{node.title}</WineHeading>
                  <WineSection>
                    {node.products.map(product => {
                      // Default Image
                      let shopifyWineImage = undefined
                      if (
                        product.media &&
                        product.media[0] &&
                        product.media[0].preview &&
                        product.media[0].preview.image &&
                        product.media[0].preview.image.originalSrc
                      ) {
                        shopifyWineImage = product.media[0].preview.image.originalSrc
                      }
                      let awardsArray = undefined
                      let awardImages = undefined
                      if (product.media && product.media.length > 1) {
                        awardsArray = product.media.slice(1)
                        awardImages = awardsArray.map(image => {
                          if (image?.preview?.image?.originalSrc) {
                            return image?.preview?.image?.originalSrc
                          } else {
                            return false
                          }
                        })
                      }
                      let year = '-'
                      let taste = '-'
                      if (product.options) {
                        let filteredYear = product.options.filter(option => option.name === 'Jahrgang')
                        let filteredTaste = product.options.filter(option => option.name === 'Klassifizierung')
                        if (filteredYear && filteredYear[0] && filteredYear[0].values && filteredYear[0].values[0]) {
                          year = filteredYear[0].values[0]
                        }
                        if (
                          filteredTaste &&
                          filteredTaste[0] &&
                          filteredTaste[0].values &&
                          filteredTaste[0].values[0]
                        ) {
                          taste = filteredTaste[0].values[0]
                        }
                      }
                      let link = '/'
                      if (product.slug) {
                        link = product.slug
                      }
                      return (
                        <Wine
                          key={product.id}
                          image={shopifyWineImage}
                          awardImages={awardImages}
                          id={product.id}
                          name={product.title}
                          description={`${year} | ${taste}`}
                          price={formatPrice(product.variants[0].price)}
                          link={link}
                        />
                      )
                    })}
                  </WineSection>
                </React.Fragment>
              )
          )}
        </Container>
      </React.Fragment>
    )
  }
}

export default Weine

export const query = graphql`
  query {
    wineCollections: allMyStoreShopifyCollection(sort: { fields: description }) {
      edges {
        node {
          id
          title
          products {
            id
            title
            options {
              name
              values
            }
            variants {
              price
            }
            slug
            media {
              preview {
                image {
                  originalSrc
                }
              }
            }
          }
        }
      }
    }
  }
`
