import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

const Container = styled.div`
  img {
    max-width: 100%;
  }
  text-align: center;
  /* margin-left: 40px;
  margin-right: 40px; */
  margin-left: 30px;
  margin-right: 30px;
  margin-bottom: 80px;
  /* width: 200px; */
  /* width: 100%; */
  /* max-width: 320px; */
  /* min-width: 200px; */
  /* flex: 1 1 20%; */
  /* :hover ${WineImage} img { */
  :hover .wine-image {
    transform: scale(1.06);
  }
  /* :hover ${AwardImage} img {
    transform: scale(1.00);
  } */
  /* :hover ${WineImageContainer} {
    filter: brightness(70%);
  } */

  /* Kind of grid fallback */
  max-width: 600px;
  @media (max-width: 420px) {
    margin-bottom: 70px;
  }
`

const WineImageContainer = styled.div`
  position: relative;
  background-color: #f8f8f8;
  margin-bottom: 20px;
  padding-top: 45px;
  padding-bottom: 45px;
  padding-right: 45px;
  padding-left: 45px;
  /* transition: all 1s ease-out !important; */
  /* filter: brightness(100%); */
  @media (max-width: 420px) {
    padding-top: 20px;
    padding-bottom: 20px;
    padding-right: 20px;
    padding-left: 20px;
  }
`

const AwardImageContainer = styled.div`
  position: absolute;
  top: 2%;
  right: 5%;
  width: 21%;
  max-width: 120px;
`

const AwardImage = styled.img`
  margin-top: 15%;
`

const WineImage = styled.img`
  transition: all 0.25s ease-out !important;
`

const WineName = styled.h2`
  font-weight: 600;
  font-size: 18px;
  line-height: 1.3;
  padding-bottom: 6px;
  color: ${p => p.theme.textColorDark};
`

const WineDescription = styled.p`
  margin-bottom: 14px;
`

const WineDivider = styled.div`
  border-top: 1px solid ${p => p.theme.lightGray};
  width: 50px;
  margin-bottom: 14px;
  margin-left: auto;
  margin-right: auto;
`

const WinePrice = styled.p`
  font-weight: 600;
  color: ${p => p.theme.textColorDark};
`

const Blank = styled.div`
  width: 100%;
  padding-top: 133.3333%;
`

const Wine = ({ image, awardImages, name, description, price, link }) => {
  return (
    <Container>
      <Link to={`/${link}`}>
        <WineImageContainer>
          {image ? <WineImage className="wine-image" alt={name} src={image} /> : <Blank />}
          <AwardImageContainer>
            {awardImages &&
              awardImages.map((image, index) => {
                return <AwardImage alt="Auszeichnung" key={index} src={image} />
              })}
          </AwardImageContainer>
        </WineImageContainer>
        <WineName>{name}</WineName>
        <WineDescription>{description}</WineDescription>
        <WineDivider />
        <WinePrice>{price}</WinePrice>
      </Link>
    </Container>
  )
}

export default Wine
